.navbarLogo {
  height: 3vw;
  padding-left: 2vw;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  overflow: hidden;
  padding-top: 4vh;
  padding-left: 35vh;
  padding-right: 35vh;
  background-color: #fff5e7;
}

.topnav a {
  float: right;
  color: #000000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: "Gill Sans", "Gill Sans MT", "Calibri", "Trebuchet MS",
    sans-serif;
  font-size: 1.5vw;
}

.topnav a {
  display: inline-block;
  position: relative;
  color: #000000;
}

.topnav a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.topnav a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.bigLogo {
  font-size: 9vw;
  color: #004600;
  text-align: center;
}

.slogan {
  font-size: 3vw;
  font-family: "Poppins", sans-serif;
}

.closer {
  line-height: 1px;
}

.mobile {
  background-color: #fff5e7;
}

.grad {
  width: 100%;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (max-width: 600px) {
  .topnav {
    display: none;
  }
  .bigLogo {
    font-size: 25px;
    color: #004600;
  }
  .grad {
    display: none;
  }

  .navbarLogo {
    display: none;
  }
  .topnav a {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .mobile {
    display: none;
  }
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 10;
  text-align: center;
  font-size: 3vw;
}

p {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  padding-left: 10vw;
  padding-right: 10vw;
  font-size: 1.2vw;
}

.appLogo {
  height: 20vw;
  padding-left: 2vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  border-radius: 6vw;
}

.desc {
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: 2vw;
  padding-bottom: 1vw;
  text-align: center;
}
.container {
  display: grid;
  align-items: center;
  grid-template-columns: 0.5fr 0.5fr 0.3fr;
  column-gap: 5px;
}

.container2 {
  display: grid;
  align-items: center;
  grid-template-columns: 0.6fr 0.5fr 0fr;
  column-gap: 5px;
}

.desc2 {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: 2vw;
  padding-bottom: 1vw;
  text-align: center;
  padding-left: 10vw;
  padding-right: 8vw;
}
